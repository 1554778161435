.PostPreview {
  margin-bottom: 64px;
}

.PostPreview:last-child {
  margin-bottom: 0;
}

.PostPreview:after {
  display: block;
  content: "";
  clear: both;
}

.PostPreview a.title {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}
.PostPreview a.title:hover {
  color: #555;
}

.PostPreview .date {
  opacity: 0.5;
}


.PostPreview img {
  float: left;
  margin: 0 32px 16px 0;
  max-width: 400px;
}

.PostPreview .ql-video {
  width: 100%;
  height: 450px;
}
