.App {
  overflow: auto;
  background: #f7f7f9;
  font-size: 18px;
  font-weight: 300;
  text-align: justify;
}

.App header {
  width: 800px;
  margin: 32px auto;
  display: flex;
}

.App header .nav {
  padding: 10px;
  line-height: 96px;
}

.App header .nav.active {
  text-decoration: none;
}

.App .main {
  margin: 0 auto 32px;
  width: 800px;
  padding: 32px;
  background: #ffffff;
}

.App a {
  color: #FF4100;
  text-decoration: underline;
}

.App a:hover {
  color: #FF9773;
  text-decoration: none;
}

.App .copyright {
  text-align: center;
  font-size: 14px;
  margin-bottom: 32px;
}
