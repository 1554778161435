.Magazine .number {
  margin-top: 64px;
}

.Magazine .number {
  display: block;
  color: #000;
  text-decoration: none;
}

.Magazine .number:hover h3 {
  color: #555;
}

.Magazine .number:hover img {
  opacity: 0.7;
}

.Magazine .pi {
  width: 100%;
}

.Magazine img {
  width: 100%;
}

