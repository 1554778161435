.Post:after {
  display: block;
  content: "";
  clear: both;
}

.Post p:first-child img {
  margin-top: 6px;
}

.Post h2 {
  color: #000;
}

.Post .date {
  opacity: 0.5;
}

.Post img {
  float: left;
  margin: 16px 32px 16px 0;
  max-width: 400px;
}

.Post .ql-video {
  width: 100%;
  height: 450px;
}
